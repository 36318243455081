import React from "react"
import { graphql } from "gatsby"

import SEO from "../../components/SEO"
import Image from "../../components/Image"

import styles from "./locations.module.scss"


const Locations = ({ data, location }) => {

  return(
    <>
      <SEO
        title="Locations"
        description="Our shop in Los Angeles is now fully open. We’re practicing strict cleaning guidelines, distancing, and PPE for employees and customers (you know the drill)."
        url={location.pathname}
      />

      <div className={styles.imageContainer}>
        <Image
          image={data.contentfulImage}
          altText={data.contentfulImage.altText}
          options={{ sizing: "art-directed" }}
          className={styles.image}
        />
        <h1 className={styles.imageHeading}>We're Open</h1>
      </div>

      <section className={styles.section}>
        <h2 className={styles.subheading}>Our shop in Los Angeles is now fully open.</h2>
      </section>
      <section className={styles.section}>  
        <div className={styles.hours}>
          <h2 className={styles.label}>LOCATION AND HOURS</h2>      
        </div>  
      </section>
      <section className={styles.section}>  
        <div className={styles.hours}>
          <span className={styles.label}>Address</span>
          <span classname={styles.info}>9638 Brighton Way</span>
        </div>
        <div className={styles.hours}>
          <span classname={styles.info}>Beverly Hills, CA 90210</span>
        </div>
      </section>
      <section className={styles.section}>        
        <div className={styles.hours}>
          <span className={styles.label}>Monday - Saturday</span>
          <span className={styles.info}>10 AM - 5 PM</span>
          <span className={styles.label}>Sunday</span>
          <span className={styles.info}>12 PM - 5 PM</span>
          <span className={styles.label}>Holidays</span>
          <span className={styles.holidays}>Hours may differ</span>
        </div>
        </section>
        <section className={styles.section}>    
        <div className={styles.hours}>
          <span className={styles.label}>Contact Us</span>
          <span className={styles.info}>(424) 245-4883</span>
        </div>
      </section>

      <section className={styles.section}>
        <ul className={styles.locationsList}>
          {/*{data.allContentfulLocationPage.nodes.map((location, i) => (*/}
          {/*  <li key={i}>*/}
          {/*    <Link to={`/locations${location.pageUrl}`}>*/}
          {/*      <Image*/}
          {/*        image={location.thumbImage}*/}
          {/*        altText={location.thumbImage.altText}*/}
          {/*        className={styles.thumbImage}*/}
          {/*      />*/}
          {/*    </Link>*/}
          {/*    <div className={styles.info}>*/}
          {/*      <h3 className={styles.city}>*/}
          {/*        {location.city}*/}
          {/*      </h3>*/}
          {/*      <h2 className={styles.location}>*/}
          {/*        <Link to={`/locations${location.pageUrl}`}>*/}
          {/*          {location.heading}*/}
          {/*        </Link>*/}
          {/*      </h2>*/}
          {/*      <ul className={styles.checklist}>*/}
          {/*        {location.checklist.map((item, j) => (*/}
          {/*          <li key={j}>*/}
          {/*            {item}*/}
          {/*          </li>*/}
          {/*        ))}*/}
          {/*      </ul>*/}
          {/*    </div>*/}
          {/*  </li>*/}
          {/*))}*/}
          {/*<li>*/}
          {/*  <Link to="/pages/mobilecloset">*/}
          {/*    <Image*/}
          {/*      image="https://cdn.shopify.com/s/files/1/1103/4464/files/truck-interior-1.jpg?v=1603154393"*/}
          {/*      altText="Truck Interior"*/}
          {/*      options={{*/}
          {/*        origin: "shopify",*/}
          {/*        width: "768",*/}
          {/*      }}*/}
          {/*      className={styles.thumbImage}*/}
          {/*    />*/}
          {/*  </Link>*/}
          {/*  <h3 className={styles.city}>*/}
          {/*    On the Road*/}
          {/*  </h3>*/}
          {/*  <h2 className={styles.location}>*/}
          {/*    <Link to="/pages/mobilecloset">*/}
          {/*      U.S. Tour*/}
          {/*    </Link>*/}
          {/*  </h2>*/}
          {/*  <ul className={styles.checklist}>*/}
          {/*    <li>Personal shopping appointments</li>*/}
          {/*    <li>Walk-in welcome</li>*/}
          {/*  </ul>*/}
          {/*</li>*/}
        </ul>
      </section>
    </>
  )
}

export default Locations

export const locationsQuery = graphql`
  query locationsQuery {
      contentfulImage(altText: {eq: "Tamara Mellon Closet in Los Angeles"}) {
          desktopImage {
              fluid(maxWidth: 2000, quality: 90) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
              }
          }
          mobileImage {
              fluid(maxWidth: 768, quality: 90) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
              }
          }
      }
#      allContentfulLocationPage(sort: {fields: createdAt}) {
#          nodes {
#              pageUrl
#              thumbImage {
#                  fluid(maxWidth: 768, quality: 90) {
#                      ...GatsbyContentfulFluid_withWebp_noBase64
#                  }
#              }
#              city
#              heading
#              checklist
#          }
#      }
  }
`
